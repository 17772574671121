<template>
  <div class="listing--action-btn" @click="clickHandler">
    <div class="btn--icon">
      <img src="@/assets/img/icons/repost.png" />
    </div>
    <div>
      <div class="top--text">
        {{ `${$t('general.btn.repost')}` }}
      </div>
      <div class="bottom--text">
        {{ `1 ${$t('general.coin')}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'repost-btn',
  props: {
    listingUuid: {
      type: String,
      required: true,
    },
  },
  components: {},
  computed: {},
  methods: {
    async repost() {
      console.log('AYO REPOST!');
      try {
        const success = await this.$store.dispatch('listing/repost', this.listingUuid);
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.repost.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        console.log('ERROR REPOST: ', e);
        // this.$swal({
        //   title: this.$i18n.t('listing.modal.repost.failedT'),
        //   text: this.$i18n.t('listing.modal.repost.failedM'),
        //   type: 'error',
        //   confirmButtonColor: '#3085d6',
        //   confirmButtonText: this.$i18n.t('general.ok'),
        //   allowOutsideClick: false,
        // });
      }
    },
    clickHandler() {
      this.$modal.show('repost-listing-modal', {
        submitAction: this.repost,
      });
      // this.$swal({
      //   title: this.$i18n.t('listing.modal.repost.title'),
      //   text: this.$i18n.t('listing.modal.repost.message'),
      //   type: 'error',
      //   showCancelButton: true,
      //   confirmButtonText: this.$i18n.t('general.yes'),
      //   cancelButtonText: this.$i18n.t('general.cancel'),
      //   allowOutsideClick: false,
      // }).then(result => {
      //   if (result.value) {
      //     this.repost();
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped></style>
