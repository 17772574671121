<template>
  <v-carousel cycle interval="5000" class="carousel--wrapper">
    <v-carousel-item v-for="banner in banners" :key="banner.uuid" class="carousel--promo-slide">
      <div class="carousel--promo-link">
        <!--      <div class="carousel&#45;&#45;promo-link mx-auto">-->
        <div class="carousel--promo-image-wrapper">
          <router-link :to="`/banner/detail/${banner.uuid}`">
            <picture>
              <source :srcset="banner.image" type="image/webp" />
              <source :srcset="getFallbackImage(banner.image)" type="image/jpeg" />
              <img
                :src="getFallbackImage(banner.image)"
                class="carousel--promo-image img-responsive"
                :alt="`${banner.title} - BeliRumah`"
              />
            </picture>
          </router-link>
          <router-link to="/promo" class="see--all" v-show="banner.is_promo">
            <div class="btn btn-primary">Lihat Semua Promo</div>
          </router-link>
        </div>
        <!--      </div>-->
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: {
    banners: {
      default: [],
      require: true,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
