<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container extra--padding mb-4">
      <banner />
      <div class="homepage--header-container">
        <coin-summary class="coin--summary-container"></coin-summary>
        <membership-summary class="homepage--membership-summary"></membership-summary>
      </div>
      <listings-header />
      <div class="my--order mt-2">
        <v-tabs center-active v-if="categories && categories.length > 0">
          <v-tab
            :href="`#tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-title-${index}`"
            @click="changeCategory(category)"
          >
            {{ category.name }}
          </v-tab>
          <v-tab-item
            :value="`tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-content-${index}`"
          >
            <SectionLoader :show="loading" />
            <div v-for="listing in listings" :key="listing.uuid" class="order--item">
              <card-listing :listing="listing" @actionDone="actionDone" />
            </div>
            <pagination
              :push-state="false"
              :meta="listingsMeta.pagination"
              @changePage="changePage"
              :maxVisibleButtons="5"
              v-if="listingsMeta"
            />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import ListingsHeader from '@/components/listing/listings-header';
import SectionLoader from '@/components/content-loading/section-loading';
import CardListing from '@/components/listing/card-listing';
import CoinSummary from '@/components/utils/coin-summary';
import MembershipSummary from '@/components/utils/membership-summary';
import Pagination from '@/components/utils/fractal-pagination';
import Banner from '@/components/home/banner';
import { mapState } from 'vuex';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ListingsHeader,
    SectionLoader,
    CardListing,
    Pagination,
    CoinSummary,
    MembershipSummary,
    Banner,
  },
  computed: {
    ...mapState({
      loading: (state) => state.listing.loading,
      categories: (state) => state.listing.categories,
      listings: (state) => state.listing.listings,
      listingsMeta: (state) => state.listing.listingsMeta,
      loggedIn: (state) => state.global.loggedIn,
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.myListing');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  async mounted() {
    this.$store.dispatch('listing/restoreInitialState');
    await this.$store.dispatch('listing/getAllListings', {});

    if (process.client) {
      // Only open takeover modal for normal users, to make sure SEO is safe
      let self = this;
      setTimeout(() => {
        self.checkTakeover();
      }, 1000);
    }
  },
  methods: {
    async changeCategory(category) {
      this.$store.commit('listing/SET_CATEGORY', category);
      await this.$store.dispatch('listing/getAllListings', {});
    },
    async changePage(page) {
      this.$store.commit('listing/SET_PAGE', page);
      await this.$store.dispatch('listing/getAllListings', {});
    },
    async actionDone() {
      await Promise.all([
        this.$store.dispatch('coins/getTotalCoins'),
        this.$store.dispatch('listingAvailability/getListingAvailability'),
      ]);
    },
    async checkTakeover() {
      if (this.loggedIn) {
        let takeover = null;
        const cookieStr = document.cookie;
        const cookies = require('cookie').parse(cookieStr || '') || {};
        if (cookies.belirumaAgentTakeover) {
          takeover = cookies.belirumaAgentTakeover;
        }

        if (!takeover) {
          let detail = await this.$store.dispatch('takeover/getTakeover');
          if (detail) {
            this.$modal.show('modal--takeover');
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/utils/variables';

.homepage--header-container {
  display: flex;
  @media #{$phones} {
    flex-wrap: wrap-reverse;
  }
}

.coin--summary-container {
  width: fit-content;
  margin-bottom: 16px;
}

.homepage--membership-summary {
  width: 100%;
  margin-bottom: 16px;
  margin-left: 24px;
  @media #{$phones} {
    margin-left: 0;
  }
}
</style>
