<template>
  <div v-if="listing" class="card--my-listing">
    <div class="left--section">
      <a target="_blank" style="text-decoration: none" @click="openDetailLink">
        <div v-if="listing.primary_photo">
          <picture>
            <source :srcset="listing.primary_photo" type="image/webp" />
            <source :srcset="getFallbackImage(listing.primary_photo)" type="image/jpeg" />
            <img :src="getFallbackImage(listing.primary_photo)" />
          </picture>
        </div>
      </a>
    </div>
    <div class="right--section">
      <div class="top--my-listing">
        <div class="left--col">
          <div class="title--text" :class="{ 'multiline-ellipsis-2': $mq === 'xs' }">
            {{ listing.title }}
            <listing-membership-expired
              class="ml-2 hide--in-mobile"
              :isMembershipExpired="isMembershipExpired"
            ></listing-membership-expired>
          </div>
          <div class="label--container">
            <div class="label">{{ listing.property_type }}</div>
          </div>
          <div
            class="listing--number"
            style="position: relative"
            :id="`copyToClipboardListingNumber-${listing.listing_number}`"
            @click="copyToClipboard(listing.listing_number)"
            v-if="listing.listing_number"
          >
            <div>
              {{ `${$t('general.listingNumber')}: ${listing.listing_number}` }}
            </div>
            <div>
              <v-icon>mdi-content-copy</v-icon>
            </div>
            <popover v-show="copyListingNumberSucceeded"></popover>
          </div>
          <div class="price">
            {{ $n(listing.price_num, 'currency', 'id-ID') }}
          </div>
        </div>
        <div class="right--col">
          <more-actions :listing="listing" @actionDone="actionDone" />
          <analytics :analytics="listingAnalytics" class="hide--in-mobile"></analytics>
          <div class="d-flex justify-end" style="margin-right: -8px">
            <listing-membership-expired
              class="hide--in-tablet hide--in-desktop"
              :isMembershipExpired="isMembershipExpired"
            ></listing-membership-expired>
          </div>
        </div>
      </div>
      <analytics class="hide--in-desktop hide--in-tablet" :analytics="listingAnalytics"></analytics>
      <div class="middle--my-listing d-flex flex-wrap mt-2">
        <div class="expired">
          <div class="expired--title">{{ $t('listing.createdDate') }}</div>
          <div class="expired--date">{{ $date.format(listing.created_at, 'dd MMM yyyy') }}</div>
        </div>
        <div class="expired">
          <div class="expired--title">{{ $t('listing.expiredDate') }}</div>
          <div class="expired--date">{{ $date.format(listing.expired_at, 'dd MMM yyyy') }}</div>
        </div>
        <div class="expired">
          <div class="expired--title">{{ $t('listing.postedDate') }}</div>
          <div class="expired--date">{{ $date.format(listing.posted_at, 'dd MMM yyyy') }}</div>
        </div>
      </div>
      <div class="label--container label--container-schedule" v-if="listing.has_repost_schedules">
        <div class="label repost--schedule-label" @click="scheduleRepostHandler">
          <img
            src="@/assets/img/icons/repost-2.png"
            alt="Auto Repost"
            style="margin-right: 4px; width: 20px; height: 20px"
          />
          <span>{{ $t('listing.activeRepostSchedules') }}</span>
        </div>
      </div>
      <div class="bottom--margin"></div>
      <div style="height: 50px" v-if="category.id === 0"></div>
      <div class="bottom--my-listing">
        <!--        <activation-toggle-->
        <!--          :activated="category.id === 0"-->
        <!--          :listingUuid="listing.uuid"-->
        <!--          :page="page"-->
        <!--          @actionDone="actionDone"-->
        <!--          v-if="category.id === 0"-->
        <!--        />-->
        <div style="flex-grow: 1"></div>
        <repost-btn :listingUuid="listing.uuid" @actionDone="actionDone" v-if="category.id !== 1" />

        <schedule-repost-btn
          :listingUuid="listing.uuid"
          @actionDone="actionDone"
          v-if="category.id === 0"
        />

        <!--        <activate-btn-->
        <!--          :listingUuid="listing.uuid"-->
        <!--          @actionDone="actionDone"-->
        <!--          v-if="category.id === 1"-->
        <!--        />-->
        <!--        <edit-btn :listingUuid="listing.uuid" />-->
        <!--        <delete-btn :listingUuid="listing.uuid" @actionDone="actionDone" v-if="category.id !== 0" />-->
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
// import ActivationToggle from '@/components/listing/partials/activation-toggle';
import ScheduleRepostBtn from '@/components/listing/partials/schedule-repost-btn';
import RepostBtn from '@/components/listing/partials/repost-btn';
// import ActivateBtn from '@/components/listing/partials/activate-btn';
// import DeleteBtn from '@/components/listing/partials/delete-btn';
// import EditBtn from '@/components/listing/partials/edit-btn';
import MoreActions from '@/components/listing/partials/more-actions';
import Analytics from '@/components/listing/partials/analytics';
import { mapGetters, mapState } from 'vuex';
import ListingMembershipExpired from '@/components/utils/listing-membership-expired.vue';
import Popover from '@/components/utils/popover.vue';

export default {
  mixins: [HelperMixin],
  components: {
    Popover,
    ListingMembershipExpired,
    // ActivationToggle,
    ScheduleRepostBtn,
    RepostBtn,
    // DeleteBtn,
    // EditBtn,
    // ActivateBtn,
    MoreActions,
    Analytics,
  },
  props: {
    listing: {
      require: true,
    },
  },
  data: () => ({
    copyListingNumberSucceeded: false,
  }),
  computed: {
    ...mapState({
      page: (state) => state.listing.page,
      category: (state) => state.listing.category,
    }),
    ...mapGetters({
      membershipType: 'global/membershipType',
    }),
    isMembershipExpired() {
      return this.membershipType === '' && this.category.id === 0;
    },
    userWebsiteUrl() {
      return process.env.VUE_APP_USER_URL;
    },
    listingAnalytics() {
      let analytics = [];
      let enquiryAmount = 0;
      for (let item of this.listing.analytics) {
        if (item.analytic_type !== 'LEADS_ENQUIRY') {
          analytics.push(JSON.parse(JSON.stringify(item)));
        } else {
          enquiryAmount = item.amount;
        }
      }
      for (let i = 0; i < analytics.length; i++) {
        if (analytics[i].analytic_type === 'LEADS_WA') {
          analytics[i].amount += enquiryAmount;
          break;
        }
      }
      return analytics;
    },
  },
  methods: {
    actionDone() {
      this.$store.dispatch('listing/getAllListings', { page: 1 });
      this.$emit('actionDone');
    },
    openDetailLink() {
      if (this.listing.links && this.listing.links.detail && this.category.id !== 1) {
        window.open(`${this.userWebsiteUrl}${this.listing.links.detail}`, '_blank');
      }
    },
    scheduleRepostHandler() {
      this.$modal.show('schedule-repost-listing-modal', {
        submitAction: this.scheduleRepost,
        listingUuid: this.listing ? this.listing.uuid : null,
      });
    },
    async scheduleRepost() {
      console.log('AYO SCHEDULE REPOST!');
      try {
        const success = await this.$store.dispatch(
          'listing/scheduleRepost',
          this.listing ? this.listing.uuid : null,
        );
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.scheduleRepost.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        console.log('ERROR SCHEDULE REPOST: ', e);
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyListingNumberStatus(true);
    },
    async handleCopyListingNumberStatus(status) {
      this.copyListingNumberSucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copyListingNumberSucceeded = false;
      }, 2000);
    },
  },
};
</script>
