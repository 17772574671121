<template>
  <v-icon class="warning--icon" @click="openWarning" v-if="isMembershipExpired === true"
    >mdi-alert</v-icon
  >
</template>

<script>
export default {
  props: ['isMembershipExpired'],
  components: {},
  computed: {},
  methods: {
    openWarning() {
      this.$swal(
        this.$t('general.swal.listingMembershipExpired.title'),
        this.$t('general.swal.listingMembershipExpired.message'),
        'warning',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.warning--icon {
  cursor: pointer;
  color: $color-beliruma-1;
}
</style>
