<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <section class="home--promo-container" v-if="banners && banners.length > 0">
      <!--      <div class="title&#45;&#45;text">-->
      <!--        {{ $t('home.promo') }}-->
      <!--      </div>-->
      <div class="slider">
        <slick-banner :banners="banners"></slick-banner>
      </div>
    </section>
  </div>
</template>

<script>
import SlickBanner from './partials/slick-banner';
export default {
  components: { SlickBanner },
  computed: {
    banners() {
      return this.$store.state.banner.banners;
    },
  },
  methods: {
    visibilityChanged(value) {
      if (value) {
        this.getBanners();
      }
    },
    getBanners() {
      this.$store.dispatch('banner/getBanner');
    },
  },
};
</script>
