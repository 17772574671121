<template>
  <div class="membership--summary-container" v-if="user && user.membership && !loading">
    <v-card class="membership--summary">
      <div class="membership--info">
        <div class="membership--name">{{ membershipType }}</div>
        <img class="membership--logo" :src="user.membership.logo" v-if="user.membership.logo" />
      </div>
      <div class="membership--expired">
        <span>{{ `${$t('general.endAt')} ` }}</span>
        <span>{{ $date.format(user.membership.expired_at, 'dd MMM yyyy') }}</span>
      </div>
      <div class="membership--cta" v-if="membershipAction">
        <router-link to="/upgrade" v-if="membershipAction.action === 'upgrade'">{{
          $t('product.upgrade')
        }}</router-link>
        <router-link to="/product" v-else-if="membershipAction.action === 'new'">{{
          $t('general.buyMembershipNow')
        }}</router-link>
        <router-link to="/renewal" v-else-if="membershipAction.action === 'renewal'">{{
          $t('product.renewal.title')
        }}</router-link>
      </div>
      <div v-if="membershipAction && membershipAction.action === 'renewal'">
        <div class="membership--renewal" v-if="membershipAction.renewal_type === 'early_bird'">
          <div>
            {{ $t('product.renewal.earlyBirdDesc1') }} <b>{{ membershipAction.renewal_due_date }}</b
            >. <br />{{ $t('product.renewal.earlyBirdDesc2') }}
          </div>
          <div>
            <button
              type="button"
              class="btn btn--whatsapp"
              style="margin-left: auto; margin-right: auto; margin-top: 8px"
              @click="handleContact"
            >
              <img
                src="@/assets/img/icons/whatsapp-1.svg"
                alt="Phone"
                style="margin-right: 4px; width: 24px"
              />
              <span style="font-family: Poppins-SemiBold">{{ $t('general.contactUs') }}</span>
            </button>
          </div>
        </div>
        <div class="membership--renewal" v-else-if="membershipAction.renewal_type === 'regular'">
          {{ $t('product.renewal.reminderDesc') }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: {},
  props: [],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      totalCoins: (state) => state.coins.totalCoins,
      membershipAction: (state) => state.global.membershipAction,
    }),
    ...mapGetters({
      membershipType: 'global/membershipType',
    }),
  },
  async mounted() {
    try {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch('coins/getTotalCoins'),
        this.$store.dispatch('listingAvailability/getListingAvailability'),
        this.$store.dispatch('global/getMembershipAction'),
      ]);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleContact() {
      let url = '';
      const contactNumber = '+' + this.$t('general.phone2');
      const text =
        'Halo, saya butuh informasi lebih lanjut mengenai promo perpanjangan early bird.';
      url = `https://wa.me/${contactNumber}?text=${text}`;

      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.membership--summary-container {
  position: relative;
  @media #{$phones} {
    width: 100% !important;
  }
}
.membership--summary {
  padding: 24px 32px;
  border-radius: 30px !important;
  @media #{$phones} {
    padding: 20px 28px;
  }
  .membership--info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    @media #{$phones} {
      margin-bottom: 8px;
    }
  }
  .membership--name {
    font-size: $font-lg;
    font-family: Poppins-Bold;
    color: $color-text-gray-2;
    @media #{$phones} {
      font-size: $font-ml;
    }
  }
  .membership--logo {
    width: 18px;
    margin-left: 8px;
  }
  .membership--expired {
    margin-bottom: 12px;
    font-size: $font-sm;
    font-family: Poppins;
    color: $color-text-black;
    text-align: center;
    @media #{$phones} {
      font-size: $font-xs;
      margin-bottom: 8px;
    }
  }
  .membership--cta {
    width: 100%;
    text-align: center;
    a {
      font-family: Poppins-SemiBold;
      color: $color-beliruma-1;
    }
    @media #{$phones} {
      font-size: $font-sm;
    }
  }
  .membership--renewal {
    font-size: $font-sm;
    margin-top: 4px;
    text-align: center;
    .btn--whatsapp {
      height: 34px;
    }
  }
}
</style>
