<template>
  <section>
    <div class="my--listing-search-repost">
      <div class="my--listing-bulk-repost">
        <router-link to="/bulk-repost" class="btn btn-primary">
          <img src="@/assets/img/icons/repost.png" />
          <span>{{ $t('general.btn.bulkRepost') }}</span>
        </router-link>
      </div>
      <form class="my--listing-search" @submit.prevent="startSearch">
        <input
          id="searchQuery"
          v-model="searchQuery"
          autocomplete="query"
          :placeholder="$t('listing.search')"
          class="basic--input"
        />
        <button class="input--btn" type="submit">
          <img src="@/assets/img/icons/search.svg" alt="Cari" />
        </button>
        <button class="delete--btn" type="button" @click="clearSearchQuery" v-if="searchQuery">
          <span class="ion-close"></span>
        </button>
      </form>
    </div>
    <div class="my--order-header my--listing-header">
      <div class="dashboard--search-container">
        <div class="dropdown--wrapper">
          <v-select
            :value="sortBy"
            @input="inputSortByHandler"
            :hint="`${$t('general.filter.sort')} ${$t(`general.filter.${sortBy.name}`)}`"
            :items="sortByList"
            :item-text="sortByLabel"
            item-value="name"
            class="basic--dropdown"
            return-object
            single-line
            hide-details
            no
          />
        </div>
      </div>
      <div class="my--listing-add">
        <router-link to="/listing/add" class="btn btn-primary">
          {{ `+ ${$t('listing.add')}` }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'listings-header',
  mixins: [HelperMixin],
  components: {},
  computed: {
    ...mapState({
      sortByList: (state) => state.listing.sortByList,
      sortBy: (state) => state.listing.sortBy,
    }),
    searchQuery: {
      get() {
        return this.$store.state.listing.searchQuery;
      },
      set(val) {
        val = this.cleanInput(val);
        this.$store.commit('listing/SET_SEARCH_QUERY', val);
        this.debounceSearch();
      },
    },
  },
  methods: {
    sortByLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    async inputSortByHandler(value) {
      this.$store.commit('listing/SET_SORT_BY', value);
      await this.$store.dispatch('listing/getAllListings', {});
    },
    async startSearch() {
      this.$store.commit('listing/SET_PAGE', 1);
      await this.$store.dispatch('listing/getAllListings', {});
    },
    debounceSearch: debounce(function () {
      this.startSearch();
    }, 500),
    clearSearchQuery() {
      this.searchQuery = null;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/utils/variables';
.my--listing-search-repost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$phones} {
    display: initial;
  }
  .my--listing-bulk-repost {
    @media #{$phones} {
      width: 100%;
      margin-bottom: 6px;
      display: flex;
      justify-content: right;
    }
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 4px;
    }
  }
}
.my--listing-search {
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin-bottom: 6px;
  .basic--input {
    padding: 11px 40px 11px 16px;
    width: 400px;
    max-width: 400px;
  }
  .delete--btn {
    position: absolute;
    top: 12px;
    right: 72px;
    @media #{$phones} {
      top: 14px;
    }
  }
  .input--btn {
    width: 48px;
    height: 48px;
    background-color: $color-beliruma-1;
    border-radius: 16px;
    margin-left: 8px;

    @media #{$phones} {
      width: 48px;
      height: 48px;
    }

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      padding: 12px;

      @media #{$phones} {
        padding: 12px;
      }
    }
  }
}
.my--listing-header {
  width: fit-content;
  float: right;
  position: relative;
  z-index: 2;
  justify-content: flex-end;
  margin-bottom: -60px;

  @media #{$phones, $tablets} {
    width: 100%;
    float: initial;
    margin-bottom: -16px;
  }
  @media #{$phones} {
    justify-content: space-between;
  }

  .dashboard--search-container {
    flex-grow: 0;

    @media #{$phones} {
      width: calc(50% - 0px);
    }

    .dropdown--wrapper {
      max-width: 260px;
      @media #{$phones} {
        width: calc(100%);
      }
    }

    .v-text-field > .v-input__control > .v-input__slot::before {
      border: none;
    }

    .v-text-field > .v-input__control > .v-input__slot::after {
      border: none;
    }
  }

  .my--listing-add {
    @media #{$phones} {
      width: calc(50% - 0px);
    }
  }
}
</style>
