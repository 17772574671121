<template>
  <div class="listing--action-btn" @click="clickHandler">
    <div class="btn--icon">
      <img src="@/assets/img/icons/repost-otomatis.png" />
    </div>
    <div>
      <div class="top--text">
        {{ `${$t('general.btn.scheduleRepost')}` }}
      </div>
      <div class="bottom--text">
        {{ `1 ${$t('general.coin')}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'schedule-repost-btn',
  props: {
    listingUuid: {
      type: String,
      required: true,
    },
  },
  components: {},
  computed: {},
  methods: {
    async scheduleRepost() {
      console.log('AYO SCHEDULE REPOST!');
      try {
        const success = await this.$store.dispatch('listing/scheduleRepost', this.listingUuid);
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.scheduleRepost.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        console.log('ERROR SCHEDULE REPOST: ', e);
      }
    },
    clickHandler() {
      this.$modal.show('schedule-repost-listing-modal', {
        submitAction: this.scheduleRepost,
        listingUuid: this.listingUuid,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
