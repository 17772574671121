<template>
  <div class="visit--container" v-if="analytics">
    <div class="visit" v-for="(data, index) in analytics" :key="`analytics-${index}`">
      <span class="visit--name">{{ `${$t('listing.' + data.analytic_type)}: ` }}</span>
      <span>{{ `${data.amount ? data.amount : 0}` }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'more-actions',
  props: ['analytics'],
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
